.editor-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
    height: 84px;
    padding: 10px;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    background-color: rgba(221, 224, 226, 0.20);
}

.public-DraftEditorPlaceholder-inner {
    position: relative;
}

@media (max-width: 768px) {
    .editor-wrapper {
        height: 80px;
    }
}

.editor-toolbar {
    height: 16px;
    min-height: 16px;
    width: 100%;
    display: flex;
}

.editor-main {
    height: 100%;
    width: 100%;
    color: #1A1A1A;
    font-family: 'GothamPro Regular', sans-serif;
    font-size: 12px;
    overflow: auto;
    cursor: text;
}

.editor-main-disabled {
    cursor: default;
}


.rdw-option-active {
    opacity: 1 !important;
}

.editor-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.public-DraftEditorPlaceholder-inner {
    color: #8D8D8D;
    font-family: 'GothamPro Regular', sans-serif;
    font-size: 12px;
}

.rdw-emoji-wrapper {
    position: relative;
}

.rdw-emoji-modal {
    position: absolute;
    bottom: 200%;
    left: -90px;
    padding: 12px;
    border-radius: 10px;
    max-height: 200px;
    overflow: auto;
    width: 200px;
    background-color: #FFFFFF;
    gap: 6px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    z-index: 1000;
}

.rdw-link-wrapper {
    position: relative;
}

.dark-mode {
    border-color: transparent !important;
    color: white !important;
}

.rdw-link-modal {
    position: absolute;
    bottom: 200%;
    left: -90px;
    padding: 12px;
    width: 240px;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    background-color: #FFFFFF;
    font-size: 12px;
    font-family: 'GothamPro Regular', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1000;
}

.dark-mode .rdw-link-modal, .rdw-emoji-modal {
    background-color: #284E72;
}


.rdw-link-modal input {
    border-radius: 4px;
    border: 1px solid #ACB0B3;
    padding: 0 4px;
}

.dark-mode .rdw-link-modal input {
    background-color: #607486;
    border-color: #607486;
}

.rdw-link-modal .rdw-link-modal-target-option {
    display: flex;
    align-items: center;
    gap: 8px;
}

.rdw-link-modal .rdw-link-modal-buttonsection {
    display: flex;
    align-items: center;
    gap: 8px;

}

.rdw-link-modal .rdw-link-modal-buttonsection button {
    font-family: 'GothamPro Medium', sans-serif;
    color: #FFFFFF;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 10px;
}

.rdw-link-modal .rdw-link-modal-buttonsection :nth-child(1) {
    background-color: #0009FF;
}

.rdw-link-modal .rdw-link-modal-buttonsection :nth-child(2) {
    background-color: #ACB0B3;
}