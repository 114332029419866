.my-edit-control .leaflet-top {
    z-index: 2200;
}

.my-edit-control .leaflet-top {
    position: fixed;
    top: 202px;
    right: 32px;
    width: 30px;
}

.my-edit-control .leaflet-control {
    margin-right: 0;
    margin-left: 0;
}

.my-edit-control .leaflet-draw-guides {
    display: none;
}

.my-edit-control .leaflet-left {
    margin-top: 106px;
}

.my-edit-control .leaflet-draw-actions {
    left: 44px
}

.my-edit-control .leaflet-draw-toolbar {
    border: none !important;
    border-radius: 4px !important;
    overflow: hidden;
}

.my-edit-control .leaflet-control {
    margin-top: 0 !important;
}

.my-edit-control .leaflet-draw {
    margin-top: 8px !important;
}

.my-edit-control .leaflet-right {
    margin-top: 8px
}

.my-edit-control .leaflet-control-lasso {
    margin-top: 8px;
}

@media (max-width: 1024px) {
    .my-edit-control .leaflet-control {
        margin-top: 0 !important;
        margin-right: 0;
    }

    .my-edit-control .leaflet-top {
        position: fixed;
        left: 16px;
        width: fit-content;
        top: 352px;
        height: 200px;
    }
}

.leaflet-popup-content-wrapper {
    max-height: 16rem;
    overflow: auto;
    color: white;
}

.leaflet-popup-content {
    margin: 8px 32px 8px 12px;
}

.my-tooltip {
    margin-top: -44px;
    max-width: 400px;
    width: 360px;
    text-align: center;
    white-space: pre-wrap;
    border: none;
    color: #FFFFFF;
    padding: 8px 32px 8px 12px;
    pointer-events: auto;
    cursor: default;
}

@media (max-width: 1024px) {
    .my-tooltip {
        max-width: 300px;
        width: 300px;
    }
}


.my-tooltip:before {
    border-top-color: rgba(13, 44, 73, 0.80)
}


.leaflet-marker-icon {
    background-color: transparent;
    border: none;
}

/*   многоугольник   */
.my-edit-control .leaflet-draw-draw-polygon {
    background-image: url("/src/assets/icons/star.svg") !important;
    background-color: #F50;
    background-position: center !important;
    background-size: 16px 16px !important;
}

.my-edit-control .leaflet-draw-draw-polygon:hover {
    background-color: #D64700;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

.my-edit-control .leaflet-draw-toolbar-button-enabled {
    background-color: #D64700;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

.my-edit-control .leaflet-draw-actions li:nth-child(2) {
    display: none;
}

/*   квадрат   */
.my-edit-control .leaflet-draw-draw-rectangle {
    background-image: url("/src/assets/icons/rectangle.svg") !important;
    background-color: #F50;
    background-position: center !important;
    background-size: 16px 16px !important;
}

.my-edit-control .leaflet-draw-draw-rectangle:hover {
    background-color: #D64700;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

.my-edit-control .leaflet-interactive {
    stroke-opacity: 1;
    stroke-width: 6;
}

/*   редактирование   */
.my-edit-control .leaflet-draw-edit-edit {
    background-image: url("/src/assets/icons/edit.svg") !important;
    background-color: #0009FF;
    background-position: center !important;
    background-size: 16px 16px !important;
    border-radius: 4px !important;
    margin-bottom: 4px;
}

.my-edit-control .leaflet-draw-edit-edit:hover {
    background-color: #030aca;
    box-shadow: 1px 1px 4px 0 #030aca inset;
}

@media (max-width: 1024px) {
    /*   многоугольник   */
    .my-edit-control .leaflet-draw-draw-polygon {
        width: 40px !important;
        height: 40px !important;
    }

    /*   квадрат   */
    .my-edit-control .leaflet-draw-draw-rectangle {
        width: 40px !important;
        height: 40px !important;
    }

    /*   редактирование   */
    .my-edit-control .leaflet-draw-edit-edit {
        width: 40px !important;
        height: 40px !important;
    }
}

.my-edit-control .leaflet-draw-edit-remove:hover {
    background-color: #c90571;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

.my-edit-control .leaflet-draw-actions {
    border-radius: 4px !important;
    overflow: hidden;
}

.my-edit-control .leaflet-draw-actions li a {
    background: rgba(38, 38, 38, 0.7) !important;
    color: #FFFFFF !important;
    font-family: 'GothamPro Regular', sans-serif !important;
    font-size: 10px;
    height: 30px !important;
    display: flex;
    align-items: center;
}

.my-edit-control .leaflet-draw-actions li a:hover {
    background: #262626 !important;
    color: #FFFFFF !important;
}